import * as React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { graphql } from 'gatsby'
import { scrollHandler } from '../../utils/functions'

type Props = {
  item?: any,
  errors?: string,
  data: any
}

class CV extends React.Component<Props> {

  componentDidMount() {
    scrollHandler();    
  }

  renderPosts() {

    const { data } = this.props;
    let post = data.wordpressPage;

    let CVData = post.acf.profile_pdf ? post.acf.profile_pdf : '';
    let profileSections = post.acf.profile_sections;

    let sections = [];

    for(var i in profileSections){
      //console.log('hogesss')
      let rows = [];
      for(var j in profileSections[i].profile_sections_section_rows){
        let styleName = profileSections[i].profile_sections_section_rows[j].profile_sections_section_rows_row_label ? '' : 'no-label';
        rows.push(
          <React.Fragment key={j}>
          <dt className={styleName}>{profileSections[i].profile_sections_section_rows[j].profile_sections_section_rows_row_label}</dt>
          <dd className={styleName} dangerouslySetInnerHTML={{ __html: profileSections[i].profile_sections_section_rows[j].profile_sections_section_rows_row_content }} />
          </React.Fragment>
        )
      }
      sections.push(
        <div className="block" key={i}>
          <h2 className="block-title">{profileSections[i].profile_sections_section_title}</h2>
          <dl>
            {rows}          
          </dl>
        </div>
      );
    }

    let CVLink:any;

    if(CVData){
      CVLink = <a href={CVData.url} className="page-menu-button" target="_blank">Download PDF</a>
    }
    return (
      <div className="container">
        {CVLink}
        <h1 className="page-title">{post.title}</h1>
        <div className="page-contents">
          <div className="info" dangerouslySetInnerHTML={{ __html: post.content }} />
          <div className="blocks">
            {sections}
          </div> 
        </div>         
      </div>
    )

  }

  renderEmpty() {
    return (
      <div>not found</div>
    )
  }  

  render() {
    const { data, errors } = this.props;

    return (
      <Layout>
        <SEO title={`${
          data ? data.wordpressPage.title : 'About'
        }`} />        
        <section className="cv-section section">
            {!errors ?
                this.renderPosts() :
                this.renderEmpty()
            }
        </section>
      </Layout>
    )
  }
}

export default CV



export const query = graphql`
      query {
      wordpressPage(slug: {eq: "cv"}) {
        title
        content
        acf {
          profile_pdf {
            url
          }
          profile_sections {
            profile_sections_section_rows {
              profile_sections_section_rows_row_content
              profile_sections_section_rows_row_label
            }
            profile_sections_section_title
          }
        }
      }
    }

  `;
